export const GET_User_Success = "get/User_success"
export const GET_User_Error = "get/User_error"
export const GET_Logout = "get/logout"


export const GET_Token= "get/token"
export const GET_Root= "get/root"

export const GET_Name= "get/name"

