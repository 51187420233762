
export const GET_SinglePage_Success = "get/SinglePage_success"
export const GET_SinglePage_Error = "get/SinglePage_error"
export const GET_SinglePage_Loading = "get/SinglePage_loading"
export const GET_Quantity_Inc= "get/quantityInc"
export const GET_Quantity_Dec= "get/quantityDec"

export const GET_DirectBuy= "get/directbuy"

export const GET_SingleDiscount= "get/singlediscount"


export const Final_Order1 = "get/finalorder1"
export const Final_Order2 = "get/finalorder2"
export const Remove_FinalOrder = "remove/order"
