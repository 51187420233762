
export const GET_Address = "get/address"
export const GET_Token = "get/token"

export const Post_Address = "post/address"

export const Choose_Address = "choose/address"

export const loading_address = "loading"
export const error_address = "error"

export const GET_Order= "get/order"